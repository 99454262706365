@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: 0, 0, 0;
  --color-primary-text: 255, 255, 255;

  --color-secondary: 44, 44, 44;
  --color-secondary-text: 255, 255, 255;

  --color-header: 0, 0, 0;
  --color-header-text: 255, 255, 255;

  --color-footer: 44, 44, 44;
  --color-footer-text: 184, 184, 184;

  --color-tab-selected: 217, 217, 217;
  --color-tab-selected-text: 0, 0, 0;
  --color-tab-regular-text: 255, 255, 255;

  --color-card: 22, 22, 22;
  --color-card-text: 225, 225, 225;

  --color-input: 15, 15, 15;
  --color-input-text: 250, 250, 250;

  --color-tooltip: 121, 121, 121;
  --color-tooltip-text: 255, 255, 255;
}

[data-theme="dark"] {
  --color-primary: 0, 0, 0;
  --color-primary-text: 255, 255, 255;

  --color-secondary: 44, 44, 44;
  --color-secondary-text: 255, 255, 255;

  --color-header: 0, 0, 0;
  --color-header-text: 255, 255, 255;

  --color-footer: 44, 44, 44;
  --color-footer-text: 184, 184, 184;

  --color-tab-selected: 217, 217, 217;
  --color-tab-selected-text: 0, 0, 0;
  --color-tab-regular-text: 255, 255, 255;

  --color-card: 22, 22, 22;
  --color-card-text: 225, 225, 225;

  --color-input: 15, 15, 15;
  --color-input-text: 250, 250, 250;

  --color-tooltip: 121, 121, 121;
  --color-tooltip-text: 255, 255, 255;
}

[data-theme="light"] {
  --color-primary: 250, 250, 250;
  --color-primary-text: 0, 0, 0;

  --color-secondary: 215, 215, 215;
  --color-secondary-text: 0, 0, 0;

  --color-header: 31, 41, 55;
  --color-header-text: 255, 255, 255;

  --color-header: 250, 250, 250;
  --color-header-text: 10, 10, 10;

  --color-tab-selected: 60, 60, 60;
  --color-tab-selected-text: 255, 255, 255;
  --color-tab-regular-text: 0, 0, 0;

  --color-card: 250, 250, 250;
  --color-card-text: 10, 10, 10;

  --color-input: 255, 255, 255;
  --color-input-text: 15, 15, 15;

  --color-tooltip: 215, 215, 215;
  --color-tooltip-text: 22, 22, 22;
}

body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Edge */
  font-family: Lora;
}

.contentHeight {
  min-height: calc(100vh - 80px);
}

.multi-select-dropdown {
  width: 200px;
}

.dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
}

.dropdown-item {
  padding: 5px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item.selected {
  background-color: #007bff;
  color: #fff;
}

/* global.css or tailwind.css */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

[data-tooltip]::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: calc(100% + 16px); /* Position the tooltip above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(var(--color-tooltip), 0.9);
  color: rgba(var(--color-tooltip-text), 1);
  padding: 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  font-size: 0.75rem; /* Tailwind's text-sm */
  line-height: 1rem;
}

[data-tooltip]::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Place triangle closer to the element */
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.5rem;
  border-style: solid;
  border-color: rgba(var(--color-tooltip), 0.9) transparent transparent
    transparent; /* Tooltip arrow */
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

.parent_display_on_parent_hover:hover .display_on_parent_hover {
  display: block;
}

.parent_display_on_parent_hover:hover .hide_on_parent_hover {
  display: none;
}
.parent_display_on_parent_hover:hover .display_on_parent_hover.flex {
  display: flex;
}

.display_on_parent_hover {
  display: none;
}

.logo-image {
  -webkit-mask-image: url("../src/images/logo.svg");
  mask-image: url("../src/images/logo.svg");
}
