.double-bounce1, .double-bounce2 {
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    animation-delay: -1.0s;
  }
  
  @keyframes sk-bounce {
    0%, 100% { transform: scale(0.0) }
    50% { transform: scale(1.0) }
  }